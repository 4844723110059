<template>
  <div>
    <b-table
      :items="items"
      id="table-list"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      hover
      bordered
      :busy="loading"
      show-empty
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>

      <template v-slot:cell(chargesEstime)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <b-form-input
          v-model="data.item.chargesEstime"
          disabled
          v-else
          type="number"
        >
        </b-form-input>
      </template>

      <template v-slot:cell(task)="data">
        <span class="text-truncate d-flex" style="max-width: 100px !important">
          <router-link
            :to="{
              name: 'show-task',
              params: {
                id: data.item.id,
                slug: data.item.slug || 'abcde',
              },
            }"
            style="max-width: 100px !important"
            class="text-truncate"
          >
            {{ data.value.libelle ? data.value.libelle : "-" }}
          </router-link></span
        >
      </template>

      <template v-slot:cell(phase)="data">
        <span>{{
          data.item.task && data.item.task.phase
            ? data.item.task.phase.libelle
            : "-"
        }}</span>
      </template>

      <template v-slot:cell(initiative)="data">
        <b-link
          v-if="
            data.item.task &&
            data.item.task.phase &&
            data.item.task.phase.initiatives
          "
          role="link"
          :to="{
            name: 'show-initiative',
            params: {
              id: data.item.task.phase.initiatives.id,
              slug: data.item.task.phase.initiatives.slug || 'abcde',
            },
          }"
          style="max-width: 100px !important"
          class="text-truncate"
        >
          {{
            data.item.task.phase.initiatives
              ? data.item.task.phase.initiatives.libelle
              : "-"
          }}
        </b-link>
        <span v-else>-</span>
      </template>

      <template v-slot:cell(monday)="data">
        <!-- <span> {{ data.item.semaine }} </span> -->
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.semaine ? data.item.semaine.lundi : 0
        }}</span>
        <b-form-input
          v-else
          :disabled="!data.item.chargesEstime"
          v-model="data.item.semaine.lundi"
          min="0"
          max="1"
          type="number"
        >
        </b-form-input>
      </template>
      <template v-slot:cell(tuesday)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.semaine ? data.item.semaine.mardi : 0
        }}</span>
        <b-form-input
          v-else
          :disabled="!data.item.chargesEstime"
          v-model="data.item.semaine.mardi"
          min="0"
          max="1"
          type="number"
        >
        </b-form-input>
      </template>
      <template v-slot:cell(wednesday)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.semaine ? data.item.semaine.mercredi : 0
        }}</span>
        <b-form-input
          v-else
          min="0"
          max="1"
          :disabled="!data.item.chargesEstime"
          v-model="data.item.semaine.mercredi"
          type="number"
        >
        </b-form-input>
      </template>

      <template v-slot:cell(thursday)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.semaine ? data.item.semaine.jeudi : 0
        }}</span>
        <b-form-input
          v-else
          min="0"
          max="1"
          :disabled="!data.item.chargesEstime"
          v-model="data.item.semaine.jeudi"
          type="number"
        >
        </b-form-input>
      </template>
      <template v-slot:cell(friday)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.semaine ? data.item.semaine.vendredi : 0
        }}</span>
        <b-form-input
          v-else
          min="0"
          max="1"
          :disabled="!data.item.chargesEstime"
          v-model="data.item.semaine.vendredi"
          type="number"
        >
        </b-form-input>
      </template>
      <template v-slot:cell(saturday)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.semaine ? data.item.semaine.samedi : 0
        }}</span>
        <b-form-input
          v-else
          min="0"
          max="1"
          :disabled="!data.item.chargesEstime"
          v-model="data.item.semaine.samedi"
          type="number"
        >
        </b-form-input>
      </template>

      <template v-slot:cell(sunday)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.semaine ? data.item.semaine.dimanche : 0
        }}</span>
        <b-form-input
          v-else
          min="0"
          :disabled="!data.item.chargesEstime"
          v-model="data.item.semaine.dimanche"
          max="1"
          type="number"
        >
        </b-form-input>
      </template>
      <template v-slot:cell(chargesRestantes)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <b-form-input
          disabled
          v-else
          v-model="data.item.chargesRestantes"
          type="number"
        >
        </b-form-input>
      </template>
      <template v-slot:cell(total)="data">
        <span v-if="data.item.semaine">{{
          parseFloat(data.item.semaine.lundi) +
            parseFloat(data.item.semaine.mardi) +
            parseFloat(data.item.semaine.mercredi) +
            parseFloat(data.item.semaine.jeudi) +
            parseFloat(data.item.semaine.vendredi) +
            parseFloat(data.item.semaine.samedi) +
            parseFloat(data.item.semaine.dimanche) || 0
        }}</span>
      </template>
      <template #cell(actions)="data">
        <table-actions
          :editMode="data.item.editMode"
          :actions="
            isAdmin || isSuper || isCollaborator || isPo
              ? semaineStatus === 'A saisir'
                ? ['edit', 'delete']
                : ['delete']
              : []
          "
          @deleteItem="$emit('deleteItem', data.item.id)"
          @editItem="$emit('editItem', data.item)"
          @confirmItem="$emit('confirmItem', data.item)"
          @cancelItem="$emit('cancelItem', data.item)"
        />
      </template>

      <template #custom-foot="data">
        <b-tr style="background: gold">
          <b-th colspan="4" class="text-center"><span>Total</span></b-th>

          <b-td variant="primary" colspan="1"
            ><span v-if="data.items.length">{{
              data.items
                .map((item) =>
                  item.semaine ? parseFloat(item.semaine.lundi) : 0
                )
                .reduce((acc, cur) => (cur ? acc + cur : acc)) || 0
            }}</span></b-td
          >
          <b-td variant="primary" colspan="1"
            ><span v-if="data.items.length">{{
              data.items
                .map((item) =>
                  item.semaine ? parseFloat(item.semaine.mardi) : 0
                )

                .reduce((acc, cur) => (cur ? acc + cur : acc)) || 0
            }}</span></b-td
          >
          <b-td variant="primary" colspan="1"
            ><span v-if="data.items.length">{{
              data.items
                .map((item) =>
                  item.semaine ? parseFloat(item.semaine.mercredi) : 0
                )
                .reduce((acc, cur) => (cur ? acc + cur : acc)) || 0
            }}</span></b-td
          >
          <b-td variant="primary" colspan="1"
            ><span v-if="data.items.length">{{
              data.items
                .map((item) =>
                  item.semaine ? parseFloat(item.semaine.jeudi) : 0
                )

                .reduce((acc, cur) => (cur ? acc + cur : acc)) || 0
            }}</span></b-td
          >
          <b-td variant="primary" colspan="1"
            ><span v-if="data.items.length">{{
              data.items
                .map((item) =>
                  item.semaine ? parseFloat(item.semaine.vendredi) : 0
                )
                .reduce((acc, cur) => (cur ? acc + cur : acc)) || 0
            }}</span></b-td
          >
          <b-td variant="primary" colspan="1"
            ><span v-if="data.items.length">{{
              data.items
                .map((item) =>
                  item.semaine ? parseFloat(item.semaine.samedi) : 0
                )
                .reduce((acc, cur) => (cur ? acc + cur : acc)) || 0
            }}</span></b-td
          >
          <b-td variant="primary" colspan="1"
            ><span v-if="data.items.length">{{
              data.items
                .map((item) =>
                  item.semaine ? parseFloat(item.semaine.dimanche) : 0
                )
                .reduce((acc, cur) => (cur ? acc + cur : acc)) || 0
            }}</span></b-td
          >
          <b-td colspan="1" style="background: gold"
            ><span v-if="data.items.length"
              >{{
                data.items
                  .map((item) =>
                    item.semaine
                      ? parseFloat(item.semaine.lundi) +
                        parseFloat(item.semaine.mardi) +
                        parseFloat(item.semaine.mercredi) +
                        parseFloat(item.semaine.jeudi) +
                        parseFloat(item.semaine.vendredi) +
                        parseFloat(item.semaine.samedi) +
                        parseFloat(item.semaine.dimanche)
                      : 0
                  )
                  .reduce((acc, cur) => (cur ? acc + cur : acc))
              }}
              Jours</span
            ></b-td
          >
          <b-td variant="primary" colspan="1"><span> </span></b-td>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: { TableActions },
  data: function () {
    return {
      sortBy: "name",
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
    };
  },
  computed: {
    ...mapGetters([
      "isAdmin",
      "isSuper",
      "isPo",
      "isCollaborator",
      "isArchitect",
    ]),

    rows() {
      return this.items.length;
    },
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    fields: Array,
    items: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    semaineStatus: {
      type: String,
      default: "",
    },
  },
};
</script>
