<template>
  <div id="imputation-historique">
    <div class="" style="margin-top: -40px">
      <div class="">
        <p class="text-secondary mb-0 p-0 col-md-6">
          <i :class="$route.meta.iconClass"></i>
          <span style="font-size: 18px" class="font-weight-light">
            Mes imputations :
          </span>
          <span style="font-size: 22px">historique</span>
        </p>
      </div>
      <div class="d-flex pt-4 px-0">
        <p class="my-auto">Rechercher imputation :</p>
        <!-- <date-picker type="gange" :value="new Date()" class="pl-1" /> -->
        <date-picker
          class="pl-1 col-md-3 m-0"
          type="date"
          format="ടww DD/MM/YYYY"
          :showTimePanel="true"
          :showWeekNumber="true"
          v-model="imputWeek"
        ></date-picker>
      </div>

      <hr class="w-100 bg-secondary" />
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <span class="rounded px-1 text-white py-1" :class="{ 'bg-primary': true }">
          S50 10/01/2021 – 16/01/2021:<span class="font-weight-bold"> Soumise</span>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <imputation-table
          :items="collaborator.imputation.tableItems"
          :fields="collaborator.imputation.tableFields"
        ></imputation-table>
      </div>
    </div>
  </div>
</template>

<script>
import ImputationTable from "./ImputationTable.vue";
export default {
  components: { ImputationTable },
  data: () => ({
    imputWeek: null,
    collaborator: {
      imputation: {
        tableItems: [
          {
            initiatives: "Initiative 1",
            phase: "Etude",
            tasks: "Architecture",
            estimated_charges: 20,
            remaining_charges: 16,
            monday: 1,
            tuesday: 1,
            wednesday: 1,
            thursday: 0,
            friday: 1,
            saturday: null,
            sunday: null,
          },
          {
            initiatives: "Initiative 1",
            phase: "Etude",
            tasks: "Architecture",
            estimated_charges: 20,
            remaining_charges: 16,
            monday: 1,
            tuesday: 1,
            wednesday: 1,
            thursday: 0,
            friday: 1,
            saturday: null,
            sunday: null,
          },
          {
            initiatives: "Transverse",
            phase: "Transverse",
            tasks: "Absence",
            estimated_charges: null,
            remaining_charges: null,
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 1,
            friday: 0,
            saturday: null,
            sunday: null,
          },
        ],
        tableFields: [
          { key: "initiative", label: "Initiatives", sortable: true },
          { key: "phase", label: "Phase", sortable: true },
          { key: "task", label: "Tâches", sortable: true },
          {
            key: "estimated_charges",
            label: "Charges estimées",
            sortable: true,
          },

          { key: "monday", label: "Lun", sortable: false },
          { key: "tuesday", label: "Mar", sortable: false },
          { key: "wednesday", label: "Mer", sortable: false },
          { key: "thursday", label: "Jeu", sortable: false },
          { key: "friday", label: "Ven", sortable: false },
          { key: "saturday", label: "Sam", sortable: false },
          { key: "sunday", label: "Dim", sortable: false },
          {
            key: "total",
            label: "Total",
            sortable: false,
            thStyle: {
              background: "gold",
            },
            variant: "primary",
          },
          {
            key: "remaining_charges",
            label: "Charges restantes",
            sortable: true,
          },
        ],
      },
    },
  }),
};
</script>

<style>
#imputation-historique .mx-input {
  height: 46px !important;
}
.mx-datepicker-popup {
  z-index: 4000 !important;
}
</style>
