<template>
  <div>
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <p class="h2">Recherche semaine à valider</p>
        <b-button variant="secondary">Valider</b-button>
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12">
        <date-picker
          v-model="selectedWeek"
          :type="'week'"
          :show-week-number="true"
          :multiple="true"
          :inline="true"
          @open="highlightWeeks"
          @change="highlightWeeks"
          @panel-change="highlightWeeks"
          @calendar-change="highlightWeeks"
        ></date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  data: () => ({
    selectedWeek: null,
    imputations: [
      {
        weekNumber: 50,
      },
      {
        weekNumber: 51,
      },
    ],
  }),
  methods: {
    highlightWeeks() {
      const weekNumbers = this.$el.querySelectorAll(".mx-week-number");
      for (const weekNumber of weekNumbers) {
        this.$nextTick(() => {
          weekNumber.classList.remove("occupied-week-number");
        });

        this.$nextTick(() => {
          if (
            this.imputations.some((imp) => {
              return imp.weekNumber === parseInt(weekNumber.innerHTML);
            })
          ) {
            weekNumber.classList.add("occupied-week-number");
          }
        });
      }
    },
  },
  mounted() {
    this.highlightWeeks();
  },
};
</script>
