<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body p-0">
        <b-table :items="items" :fields="fields" bordered striped show-empty>
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(imputation_week)="data">
            <span>{{ data.value }} JH / 5</span>
          </template>
          <template v-slot:cell(date)="data">
            <span
              >{{
                "S" +
                data.item.semaine +
                " " +
                moment(data.value, "DD/MM/YYYY")
                  .startOf("week")
                  .format("DD/MM/YYYY") +
                " - " +
                moment(data.value, "DD/MM/YYYY")
                  .endOf("week")
                  .format("DD/MM/YYYY")
              }}
            </span>
          </template>
          <template v-slot:cell(approbateur)="data">
            <TableResponsable
              v-if="data.item.status === 'Validée'"
              :responsable="data.value"
            />
            <span v-else>-</span>
          </template>
          <template v-slot:cell(collaborateur)="data">
            <span v-if="data.value">
              {{ data.value.firstname + " " + data.value.lastname }}</span
            >
            <span v-else> -</span>
          </template>

          <template v-slot:cell(status)="data">
            <span
              class="text-white p-1 rounded"
              :class="{
                'bg-success': data.value === 'Validée',
                'bg-warning': data.value === 'Soumise',
              }"
              >{{ data.value }}
            </span>
          </template>
          <template #cell(actions)="data">
            <TableActions
              :actions="isManager || isAdmin || isSuper ? ['show'] : []"
              @showItem="showItem(data.item)"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div v-show="collaboratorImputationModal" to="modals-xyz-548">
      <Modal
        @close="collaboratorImputationModal = !collaboratorImputationModal"
      >
        <collaborator-imputation
          @close="collaboratorImputationModal = !collaboratorImputationModal"
          :selectedWeek="selectedWeek"
        />
      </Modal>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/custom/Modal.vue";
import CollaboratorImputation from "./CollaboratorImputation.vue";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import TableActions from "@/components/custom/TableActions.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    TableResponsable,
    Modal,
    CollaboratorImputation,
    TableActions,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      collaboratorImputationModal: false,
      selectedWeek: null,
      tableItems: [
        {
          collaborator: "Steeve EWORE",
          imputation_week: 5,
          actions: ["show"],
          approbateur: {
            firstname: "Echalh",
            lastname: "Adil",
          },
          status: "Soumise",
          week: "S40  01/01/2022 – 07/01/2022",
        },
        {
          collaborator: "Joel ASSOG",
          approbateur: {
            firstname: "Echalh",
            lastname: "Adil",
          },
          imputation_week: 5,
          status: "Validée",
          week: "S40  01/01/2022 – 07/01/2022",

          actions: ["show"],
        },
      ],
      fields: [
        { key: "collaborateur", label: "Collaborateur", sortable: true },
        { key: "date", label: "Semaine", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "approbateur", label: "Validé par", sortable: true },
        // { key: "imputation_week", label: "Imputation Semaine", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    };
  },
  methods: {
    showItem(item) {
      if (this.isManager || this.isAdmin || this.isSuper) {
        this.selectedWeek = item;
        this.collaboratorImputationModal = true;
      }
    },
  },

  computed: {
    ...mapGetters(["isManager", "isAdmin", "isSuper"]),
    //  returns the approbateur task list
    myTeamWeeks() {
      return this.tableItems.filter((item) =>
        this.teamWeekStatus ? item.status === this.teamWeekStatus : true
      );
    },
  },
};
</script>
