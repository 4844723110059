<template>
  <div>
    <div class="row">
      <div
        class="
          d-flex
          col-auto
          flex-wrap
          justify-content-between
          h2
          d-flex
          text-secondary
        "
        style="margin-top: -64px; width: calc(100% - 45px)"
      >
        <i
          style="font-size: 22px"
          class="my-auto"
          :class="$route.meta.iconClass"
        ></i>

        <span style="font-size: 18px" class="my-auto font-weight-light"
          >Imputation :
        </span>
        <span class="my-auto ml-1" style="font-size: 22px">
          {{
            selectedWeek && selectedWeek.collaborateur
              ? selectedWeek.collaborateur.firstname +
                " " +
                selectedWeek.collaborateur.lastname
              : "-"
          }}</span
        >
        <span class="ml-auto my-auto">
          <b-button
            v-if="selectedWeek && selectedWeek.status !== 'A saisir'"
            variant="primary"
            @click="changeWeekStatus('A saisir')"
            >A Ressaisir</b-button
          >
          <b-button
            v-if="selectedWeek && selectedWeek.status !== 'Validée'"
            @click="changeWeekStatus('Validée')"
            variant="secondary"
            >Valider</b-button
          >
        </span>
      </div>
      <hr class="w-100" />
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <span
          class="rounded p-1 text-white"
          :class="{
            'bg-primary': selectedWeek && selectedWeek.status !== 'Validée',
            'bg-success': selectedWeek && selectedWeek.status === 'Validée',
          }"
        >
          {{
            "S" +
            parseInt(
              moment(selectedWeek ? selectedWeek.date : new Date()).week() + 1
            )
          }}
          {{
            moment(selectedWeek ? selectedWeek.date : new Date())
              .startOf("week")
              .format("DD/MM/YYYY")
          }}
          –
          {{
            moment(selectedWeek ? selectedWeek.date : new Date())
              .endOf("week")
              .format("DD/MM/YYYY")
          }}
          :<span class="font-weight-bold">
            {{ selectedWeek ? selectedWeek.status : "-" }}</span
          >
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <imputation-table
          :items="imputationsList"
          :fields="ImputationTableFields"
          :perPage="5"
          :currentPage="currentPage"
        ></imputation-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ImputationTable from "./ImputationTable.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  components: { ImputationTable },
  props: {
    selectedWeek: {
      type: Object,
    },
  },
  data: () => ({
    currentPage: 1,
    perPage: 5,

    weekStatus: "Validée",
    collaborator: {
      imputation: {
        tableItems: [
          {
            initiatives: "Initiative 1",
            phase: "Etude",
            tasks: "Architecture",
            estimated_charges: 20,
            remaining_charges: 16,
            monday: 1,
            tuesday: 1,
            wednesday: 1,
            thursday: 0,
            friday: 1,
            saturday: null,
            sunday: null,
          },
          {
            initiatives: "Initiative 1",
            phase: "Etude",
            tasks: "Architecture",
            estimated_charges: 20,
            remaining_charges: 16,
            monday: 1,
            tuesday: 1,
            wednesday: 1,
            thursday: 0,
            friday: 1,
            saturday: null,
            sunday: null,
          },
          {
            initiatives: "Transverse",
            phase: "Transverse",
            tasks: "Absence",
            estimated_charges: null,
            remaining_charges: null,
            monday: 0,
            tuesday: 0,
            wednesday: 0,
            thursday: 1,
            friday: 0,
            saturday: null,
            sunday: null,
          },
        ],
        tableFields: [
          { key: "initiatives", label: "Initiatives", sortable: true },
          { key: "phase", label: "Phase", sortable: true },
          { key: "tasks", label: "Tâches", sortable: true },
          {
            key: "estimated_charges",
            label: "Charges estimées",
            sortable: true,
          },
          { key: "monday", label: "Lun 14/02", sortable: false },
          { key: "tuesday", label: "Mar 15/02", sortable: false },
          { key: "wednesday", label: "Mer 16/02", sortable: false },
          { key: "thursday", label: "Jeu 17/02", sortable: false },
          { key: "friday", label: "Ven 18/02", sortable: false },
          { key: "saturday", label: "Sam 19/02", sortable: false },
          { key: "sunday", label: "Dim 20/02", sortable: false },
          { key: "total", label: "Total", sortable: false, variant: "primary" },

          {
            key: "remaining_charges",
            label: "Charges restantes",
            sortable: true,
          },
        ],
      },
    },
  }),
  computed: {
    ...mapGetters("imputation", ["IMPUTATIONS"]),
    imputationsList() {
      if (this.selectedWeek)
        return this.IMPUTATIONS.filter(
          (imputation) =>
            moment(imputation.semaine.semaine, "DD/MM/YYYY").isoWeek() + 1 ===
            this.selectedWeek.semaine
        );
      return [];
    },
    rows() {
      return this.imputationsList.length;
    },
    ImputationTableFields() {
      return [
        { key: "initiative", label: "Initiatives", sortable: true },
        { key: "phase", label: "Phase", sortable: true },
        {
          key: "task",
          label: "Tâches",
          tdStyle: {
            padding: "0px",
            width: "50px !important",
            minWidth: "30px !important",
          },
          sortable: true,
        },
        {
          key: "chargesEstime",
          label: "Charges estimées",
          sortable: true,
        },
        {
          key: "monday",
          label: `Lun ${moment(this.selectedWeek ? this.selectedWeek.date : new Date())
            .startOf("week")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "tuesday",
          label: `Mar ${moment(this.selectedWeek ? this.selectedWeek.date : new Date())
            .startOf("week")
            .add(1, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "wednesday",
          label: `Mer ${moment(this.selectedWeek ? this.selectedWeek.date : new Date())
            .startOf("week")
            .add(2, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "thursday",
          label: `Jeu ${moment(this.selectedWeek ? this.selectedWeek.date : new Date())
            .startOf("week")
            .add(3, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "friday",
          label: `Ven ${moment(this.selectedWeek ? this.selectedWeek.date : new Date())
            .startOf("week")
            .add(4, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "saturday",
          label: `Sam ${moment(this.selectedWeek ? this.selectedWeek.date : new Date())
            .startOf("week")
            .add(5, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "sunday",
          label: `Dim ${moment(this.selectedWeek ? this.selectedWeek.date : new Date())
            .startOf("week")
            .add(6, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "total",
          label: "Total",
          sortable: false,
          thStyle: {
            background: "gold",
          },
          variant: "primary",
        },

        {
          key: "chargesRestantes",
          label: "Charges restantes",
          sortable: true,
        },
      ];
    },
  },
  methods: {
    changeWeekStatus(newStatus) {
      if (newStatus === "Validée")
        this.IMPUTATIONS.map((imputation) => {
          if (
            moment(imputation.semaine.semaine, "DD/MM/YYYY").isoWeek() + 1 ===
            this.selectedWeek.semaine
          ) {
            this.$store
              .dispatch("semaine/updateSemaine", {
                id: imputation.semaine.id,
                status: newStatus,
              })
              .then(() => {
                this.$store.dispatch("imputation/fetchAllImputations");
              });
            this.$emit("close");
            Swal.fire(newStatus, "", "success");
          }
        });
      //
      else
        Swal.fire({
          title: "Etes-vous sûr(e) ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Annuler",
          confirmButtonText: "Confirmer",
        }).then((result) => {
          if (result.value) {
            this.IMPUTATIONS.map((imputation) => {
              if (
                moment(imputation.semaine.semaine, "DD/MM/YYYY").isoWeek() +
                  1 ===
                this.selectedWeek.semaine
              ) {
                this.$store
                  .dispatch("semaine/updateSemaine", {
                    id: imputation.semaine.id,
                    status: newStatus,
                  })
                  .then(() => {
                    this.$store.dispatch("imputation/fetchAllImputations");
                  });
                this.$emit("close");
                Swal.fire("A ressaisir", "", "success");
              }
            });
          }
        });
    },
  },
};
</script>

<style></style>
