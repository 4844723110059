<template>
  <div>
    <div class="row">
      <div class="col-12 bg-white p-2">
        <b-tabs
          v-model="activeTab"
          :value="$store.state.tabs.activeTab"
          @changed="activeTab = $store.state.tabs.activeTab"
          @activate-tab="
            (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
          "
          class="tab-solid tab-solid-primary mt-3"
        >
          <b-tab id="imputation" title="Mes imputations">
            <div class="d-flex mb-1 mt-3">
              <div class="col-md-6 my-auto p-0">
                <div class="row col-md-12 mx-0 p-0">
                  <div
                    class="col-12 px-0 col-md-4 d-flex align-items-center pr-1"
                  >
                    <span class="h5 mb-0 mr-0 my-auto"
                      >Imputations à réaliser:
                    </span>
                  </div>
                  <div class="col-12 px-0 col-md-4 pr-1">
                    <button
                      class="p-0 border-0 bg-transparent"
                      @click="datePickerFunction"
                    >
                      <date-picker
                        class="p-0 m-0 w-100"
                        type="week"
                        id="main-date-picker"
                        role="button"
                        format="ടww DD/MM/YYYY"
                        :showTimePanel="true"
                        :showWeekNumber="true"
                        v-model="imputWeek"
                      ></date-picker>
                    </button>
                  </div>
                  <!-- <div class="col-12 px-0 col-md-4 d-flex pr-1">
                    <b-button
                      class="px-2 w-100"
                      variant="secondary"
                      :disabled="
                        !(isAdmin || isSuper || isCollaborator || isPo)
                      "
                      @click="
                        () =>
                          isAdmin || isSuper || isCollaborator || isPo
                            ? (historiqueImputationModal = true)
                            : ''
                      "
                      >Historique Imputations</b-button
                    >
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 my-auto p-0">
                <div class="row justify-content-end col-12 p-0 m-0">
                  <div class="my-auto px-0 pl-1 col-md-4">
                    <b-button
                      style="height: 44.22px"
                      class="px-2 w-100"
                      variant="secondary"
                      @click="
                        () =>
                          isAdmin || isSuper || isCollaborator || isPo
                            ? (myActivityModal = true)
                            : ''
                      "
                    >
                      <i class="mdi mdi-ballot mr-2"></i>
                      Mes activités
                    </b-button>
                  </div>
                  <!-- {{ weekStatus }} -->
                  <div class="px-0 col-md-4 w-100 mx-0 pl-1">
                    <b-button
                      variant="gradient-primary"
                      @click="DraftWeek"
                      :disabled="
                        !(isAdmin || isSuper || isCollaborator || isPo) ||
                        weekStatus === 'Brouillon' ||
                        weekStatus === 'Validée'
                      "
                      class="px-2 h-100 w-100"
                      :style="{
                        cursor:
                          !(isAdmin || isSuper || isCollaborator || isPo) ||
                          weekStatus === 'Brouillon' ||
                          weekStatus === 'Validée'
                            ? 'no-drop'
                            : 'pointer',
                      }"
                    >
                      Enregistrer brouillon
                    </b-button>
                  </div>

                  <div class="col-md-4 px-0 w-100 pl-1">
                    <b-button
                      :disabled="
                        !(isAdmin || isSuper || isCollaborator || isPo) ||
                        !validable ||
                        weekStatus === 'Validée'
                      "
                      @click="validateSemaine"
                      variant="gradient-primary"
                      class="px-2 h-100 w-100"
                      :style="{
                        cursor:
                          !(isAdmin || isSuper || isCollaborator || isPo) ||
                          !validable ||
                          weekStatus === 'Validée'
                            ? 'no-drop'
                            : 'pointer',
                      }"
                    >
                      Soumettre validation
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-0 bg-secondary" />

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div
                    class="py-2 d-flex"
                    style="justify-content: space-between"
                  >
                    <span
                      v-if="!loadingData"
                      class="rounded px-1 text-white"
                      :class="{
                        'bg-primary': weekStatus === 'Brouillon',
                        'bg-gray': weekStatus === 'Soumise',
                        'bg-success': weekStatus === 'A saisir',
                      }"
                    >
                      {{
                        "S" +
                        parseInt(moment(imputWeek || new Date()).week() + 1)
                      }}
                      {{
                        moment(imputWeek || new Date())
                          .startOf("week")
                          .format("DD/MM/YYYY")
                      }}
                      –
                      {{
                        moment(imputWeek || new Date())
                          .endOf("week")
                          .format("DD/MM/YYYY")
                      }}:<span class="font-weight-bold"> {{ weekStatus }}</span>
                    </span>
                    <span
                      v-if="semaineApprobateur"
                      class="px-1 ml-auto text-danger"
                    >
                      Approbateur:
                      <span class="font-weight-bold">{{
                        semaineApprobateur.firstname +
                        " " +
                        semaineApprobateur.lastname
                      }}</span>
                    </span>
                  </div>
                  <div class="card-body p-0">
                    <ImputationTable
                      :loading="loadingData"
                      @showItem="showImputation = !showImputation"
                      @deleteItem="deleteImputation"
                      @editItem="editItem"
                      @confirmItem="confirmItem"
                      @cancelItem="cancelItem"
                      :items="tableItems"
                      :fields="ImputationTableFields"
                      :semaineStatus="weekStatus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Mon équipe">
            <!-- {{TEAM_IMPUTATIONS}} -->
            <div class="row mb-1">
              <div class="col-12">
                <div class="d-flex">
                  <div class="p-0 ol-12 col-md-3 d-flex align-items-center">
                    <span class="h5 mb-0 my-auto"
                      >Liste des semaines collaborateurs
                    </span>
                  </div>
                  <div class="col-12 col-md-9 d-flex pr-0 align-items-center">
                    <span class="mb-0 my-auto pr-2 ml-auto">Recherche: </span>

                    <v-select
                      :options="responsablesList"
                      label="text"
                      :reduce="(respo) => respo.value"
                      placeholder="Collaborateur"
                      class="col-md-2 px-0 pl-1"
                      v-model="selectedCollab"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                    <date-picker
                      class="col-md-2 px-0 pl-1"
                      type=""
                      placeholder="Semaine début"
                      format="ടww DD/MM/YYYY"
                      :showTimePanel="true"
                      :showWeekNumber="true"
                      v-model="imputStartWeek"
                    ></date-picker>
                    <date-picker
                      class="col-md-2 px-0 pl-1"
                      type=""
                      format="ടww DD/MM/YYYY"
                      :showTimePanel="true"
                      :showWeekNumber="true"
                      placeholder="Semaine fin"
                      v-model="imputEndWeek"
                    ></date-picker>

                    <v-select
                      placeholder="Statut"
                      :options="['Soumise', 'Validée']"
                      class="col-md-2 px-0 pl-1"
                      v-model="teamWeekStatus"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-0 bg-secondary" />

            <div class="row">
              <WeeksCollaborator :items="weeksCollaboratorItems" />
            </div>
          </b-tab>
          <b-tab title="Tableau de bord" v-if="false" lazy>
            <b-card>
              <div class="row mb-5">
                <div class="col-12 col-md-6">
                  <pie-c3
                    :options="{
                      data: {
                        columns: [
                          ['Charges estimées', 20],
                          ['Charges', 80],
                        ],
                        type: 'pie',
                      },
                      color: {
                        pattern: ['#FFC000', '#072648', '#8E97FC', '#A7B3FD'],
                      },
                      padding: {
                        top: 0,
                        right: 0,
                        bottom: 30,
                        left: 0,
                      },
                    }"
                  ></pie-c3>
                </div>
                <div class="col-12 col-md-6">
                  <pie-c3
                    :options="{
                      data: {
                        columns: [
                          ['Charge Restantes', 16],
                          ['Charges', 84],
                        ],
                        type: 'pie',
                      },
                      color: {
                        pattern: ['#FFC000', '#072648', '#8E97FC', '#A7B3FD'],
                      },
                      padding: {
                        top: 0,
                        right: 0,
                        bottom: 30,
                        left: 0,
                      },
                    }"
                  ></pie-c3>
                </div>
              </div>
              <hr />
              <div class="row mb-3">
                <div class="col-12">
                  <full-calendar :events="events" />
                </div>
              </div>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div v-show="searchWeek" to="modals-xyz-548">
      <Modal @close="searchWeek = !searchWeek">
        <SearchWeek />
      </Modal>
    </div>

    <div v-show="historiqueImputationModal" to="modals-xyz-548">
      <Modal @close="historiqueImputationModal = !historiqueImputationModal">
        <historique-imputation />
      </Modal>
    </div>
    <div v-if="myActivityModal" to="modals-xyz-548">
      <Modal @close="myActivityModal = !myActivityModal">
        <search-activity
          :selectedWeek="imputWeek"
          @close="myActivityModal = !myActivityModal"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/custom/Modal.vue";
import ImputationTable from "./components/ImputationTable.vue";
import SearchWeek from "./components/SearchWeek.vue";
import HistoriqueImputation from "./components/HistoriqueImputation.vue";
import pieC3 from "@/components/charts/c3-chart/pie-c3";
import FullCalendar from "@/components/custom/FullCalendar";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import moment from "moment";
import SearchActivity from "./SearchActivity";
import WeeksCollaborator from "./components/WeeksCollaborator.vue";
import { uniqBy, groupBy } from "lodash";

export default {
  components: {
    ImputationTable,
    SearchWeek,
    Modal,
    HistoriqueImputation,
    pieC3,
    FullCalendar,
    SearchActivity,
    WeeksCollaborator,
  },
  data() {
    return {
      selectedCollab: null,
      imputWeek: null,
      imputStartWeek: null,
      imputEndWeek: null,
      loadingData: true,
      historiqueImputationModal: false,
      myActivityModal: false,
      activeTab: this.$store.state.tabs.activeTab,
      // activeTab: 1,
      events: [
        {
          title: "Lorem 1",
          start: window.moment().day("Lundi").week(50),
          end: window.moment().day("Lundi").week(50).add(4, "d"),
        },
        {
          title: "Lorem 2",
          start: window.moment().day("Lundi").week(51),
          end: window.moment().day("Lundi").week(51).add(4, "d"),
        },
      ],

      tableItems: [],
      showAddImputation: false,
      showEditImputation: false,
      showImputation: false,
      searchWeek: false,
      searchActivityModal: false,
      collaboratorImputationModal: false,
      teamWeekStatus: null,
    };
  },
  methods: {
    deleteImputation: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("imputation/deleteImputation", id).then(() => {
            this.$store.dispatch("imputation/fetchAllImputations").then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
          });
        }
      });
    },
    editItem: function (item) {
      this.$store.dispatch("imputation/fetchAllImputations").then(() => {
        this.tableItems = this.tableItems.map((imputation) =>
          item.id === imputation.id
            ? {
                ...imputation,
                editMode: true,
              }
            : imputation
        );
      });
    },
    datePickerFunction() {
      var datePickerRows = [];

      datePickerRows = document.getElementsByClassName("mx-week-number");
      for (let index = 0; index < datePickerRows.length; index++) {
        !!this.weeksList.find((semaine) => {
          if (
            semaine.status === "Brouillon" &&
            semaine.semaineNumber + 1 ===
              parseInt(
                document.getElementsByClassName("mx-week-number")[index]
                  .innerText
              )
          ) {
            document.getElementsByClassName("mx-week-number")[
              index
            ].style.background = "rgb(255 192 0)";
            document.getElementsByClassName("mx-week-number")[
              index
            ].style.opacity = 100;
          }
        });
      }
      var datePickerInput = document.getElementById("main-date-picker");
      datePickerInput.firstChild.firstChild.value = `S${parseInt(
        moment(this.imputWeek || new Date()).week() + 1
      )} ${moment(this.imputWeek || new Date())
        .startOf("week")
        .format("DD/MM/YYYY")} – ${moment(this.imputWeek || new Date())
        .endOf("week")
        .format("DD/MM/YYYY")}`;
    },

    confirmItem(item) {
      this.$store
        .dispatch("semaine/updateSemaine", {
          id: item.semaine.id,
          lundi: parseFloat(item.semaine.lundi),
          mardi: parseFloat(item.semaine.mardi),
          mercredi: parseFloat(item.semaine.mercredi),
          jeudi: parseFloat(item.semaine.jeudi),
          vendredi: parseFloat(item.semaine.vendredi),
          samedi: parseFloat(item.semaine.samedi),
          dimanche: parseFloat(item.semaine.dimanche),
        })
        .then(() => {
          this.$store
            .dispatch("imputation/updateImputation", {
              id: item.id,
              chargesEstime: parseInt(item.chargesEstime),
              chargesRestantes: parseInt(item.chargesRestantes),
            })
            .then(() => {
              this.$store
                .dispatch("imputation/fetchAllImputations")
                .then(() => {
                  Swal.fire("L'imputation est mise à jour !", "", "success");
                });
            });
        });
    },
    cancelItem(item) {
      window.console.log(item);
      this.$store.dispatch("imputation/fetchAllImputations");
    },
    validateSemaine() {
      if (this.isAdmin || this.isSuper || this.isCollaborator || this.isPo) {
        if (this.validable) {
          this.tableItems.map((imputation) => {
            this.$store.dispatch("semaine/updateSemaine", {
              id: imputation.semaine.id,
              status: "Soumise",
            });
          });
          this.tableItems.length &&
            this.$store.dispatch("imputation/fetchAllImputations").then(() => {
              // Swal.fire("L'imputation est mise à jour !", "", "success");
              this.$store.dispatch("semaine/fetchAllSemaines").then(() => {
                this.datePickerFunction();
              });
            });
        }
      }
    },
    DraftWeek() {
      if (this.isAdmin || this.isSuper || this.isCollaborator || this.isPo) {
        this.tableItems.map((imputation) => {
          this.$store.dispatch("semaine/updateSemaine", {
            id: imputation.semaine.id,
            status: "Brouillon",
          });
        });
        this.tableItems.length &&
          this.$store.dispatch("imputation/fetchAllImputations").then(() => {
            // Swal.fire("L'imputation est mise à jour !", "", "success");

            this.$store.dispatch("semaine/fetchAllSemaines").then(() => {
              this.datePickerFunction();
            });
          });
      }
    },
  },

  created() {
    this.$store.dispatch("imputation/fetchAllImputations").then(() => {
      this.loadingData = false;
    });
    this.$store.dispatch("semaine/fetchAllSemaines");
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("imputation/fetchTeamImputations");
  },
  computed: {
    ...mapGetters("imputation", ["IMPUTATIONS", "TEAM_IMPUTATIONS"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("semaine", ["SEMAINES"]),
    ...mapGetters([
      "isAdmin",
      // "isManager",
      "isSuper",
      "isPo",
      "isCollaborator",
      "isArchitect",
    ]),

    weeksList() {
      return this.SEMAINES.map((semaine) => ({
        semaineNumber: moment(semaine.semaine, "DD/MM/YYYY").isoWeek(),
        status: semaine.status,
      }));
    },

    //  returns the user task list
    myTeamWeeks() {
      return this.myTeam.tableItems.filter((item) =>
        this.teamWeekStatus ? item.status === this.teamWeekStatus : true
      );
    },
    // return Boolean if we can change the status of the week to validate
    validable() {
      return (
        this.tableItems.reduce(
          (r, imputation) =>
            r +
            parseFloat(imputation.semaine ? imputation.semaine.lundi : 0) +
            parseFloat(imputation.semaine ? imputation.semaine.mardi : 0) +
            parseFloat(imputation.semaine ? imputation.semaine.mercredi : 0) +
            parseFloat(imputation.semaine ? imputation.semaine.jeudi : 0) +
            parseFloat(imputation.semaine ? imputation.semaine.vendredi : 0) +
            parseFloat(imputation.semaine ? imputation.semaine.samedi : 0) +
            parseFloat(imputation.semaine ? imputation.semaine.dimanche : 0),
          0
        ) === 5 &&
        this.tableItems
          .map(
            (imputation) =>
              this.weekStatus !== "Soumise" &&
              typeof (imputation.semaine
                ? imputation.semaine.lundi
                : 0 + imputation.semaine
                ? imputation.semaine.mardi
                : 0 + imputation.semaine
                ? imputation.semaine.mercredi
                : 0 + imputation.semaine
                ? imputation.semaine.jeudi
                : 0 + imputation.semaine
                ? imputation.semaine.vendredi
                : 0 + imputation.semaine
                ? imputation.semaine.samedi
                : 0 + imputation.semaine
                ? imputation.semaine.dimanche
                : 0) === "number"
          )
          .every((x) => x)
      );
    },

    // retruns the custom table feilds with dynamic date of days
    ImputationTableFields() {
      return [
        { key: "initiative", label: "Initiatives", sortable: true },
        { key: "phase", label: "Phase", sortable: true },
        {
          key: "task",
          label: "Tâches",
          tdStyle: {
            padding: "0px",
            width: "50px !important",
            minWidth: "30px !important",
          },
          sortable: true,
        },
        {
          key: "chargesEstime",
          label: "Charges estimées",
          sortable: true,
        },
        {
          key: "monday",
          label: `Lun ${moment(this.imputWeek || new Date())
            .startOf("week")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "tuesday",
          label: `Mar ${moment(this.imputWeek || new Date())
            .startOf("week")
            .add(1, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "wednesday",
          label: `Mer ${moment(this.imputWeek || new Date())
            .startOf("week")
            .add(2, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "thursday",
          label: `Jeu ${moment(this.imputWeek || new Date())
            .startOf("week")
            .add(3, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "friday",
          label: `Ven ${moment(this.imputWeek || new Date())
            .startOf("week")
            .add(4, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "saturday",
          label: `Sam ${moment(this.imputWeek || new Date())
            .startOf("week")
            .add(5, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "sunday",
          label: `Dim ${moment(this.imputWeek || new Date())
            .startOf("week")
            .add(6, "days")
            .format("DD/MM")}`,
          sortable: false,
        },
        {
          key: "total",
          label: "Total",
          sortable: false,
          thStyle: {
            background: "gold",
          },
          variant: "primary",
        },

        {
          key: "chargesRestantes",
          label: "Charges restantes",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ];
    },
    // returuns the status of the weeks lined to the imputation table
    weekStatus() {
      return this.tableItems &&
        this.tableItems.length &&
        this.tableItems[this.tableItems.length - 1].semaine
        ? this.tableItems[this.tableItems.length - 1].semaine.status
        : "A saisir";
    },
    semaineApprobateur() {
      return this.tableItems.length
        ? this.tableItems[this.tableItems.length - 1].approbateur
        : null;
    },
    weeksCollaboratorItems() {
      return this.IMPUTATIONS.length
        ? uniqBy(
            this.IMPUTATIONS.map((imputation) => ({
              collaborateur: imputation.collaborateur,
              approbateur: imputation.approbateur,
              semaine:
                moment(
                  imputation.semaine ? imputation.semaine.semaine : new Date(),
                  "DD/MM/YYYY"
                ).isoWeek() + 1,
              date: imputation.semaine
                ? imputation.semaine.semaine
                : new Date(),
              status: imputation.semaine
                ? imputation.semaine.status
                : "A saisir",
              id: imputation.semaine ? imputation.semaine.id : null,
            })),
            "semaine"
          )
            .filter(
              (imputation) =>
                imputation.status === "Soumise" ||
                imputation.status === "Validée"
            )
            .filter((imputation) =>
              this.teamWeekStatus
                ? imputation.status === this.teamWeekStatus
                : true
            )
            .filter((imputation) =>
              this.selectedCollab
                ? imputation.collaborateur["@id"] === this.selectedCollab
                : true
            )
            .filter((imputation) =>
              //started At
              this.imputStartWeek
                ? moment(imputation.date, "DD/MM/YYYY")
                    .startOf("week")
                    .format("YYYY/MM/DD") <=
                  moment(this.imputStartWeek, "DD/MM/YYYY").format("YYYY/MM/DD")
                : true
            )
            .filter((imputation) =>
              this.imputEndWeek
                ? moment(imputation.date, "DD/MM/YYYY")
                    .endOf("week")
                    .format("DD/MM/YYYY") >=
                  moment(this.imputEndWeek, "DD/MM/YYYY").format("YYYY/MM/DD")
                : true
            )
        : [];
    },
    responsablesList() {
      return this.RESPONSABLES.map((responsable) => ({
        text: responsable.firstname + " " + responsable.lastname,
        value: `/api/users/${responsable.id}`,
      }));
    },
  },
  updated() {
    this.datePickerFunction();
  },

  watch: {
    IMPUTATIONS: function () {
      if (this.IMPUTATIONS) {
        // window.console.log(this.IMPUTATIONS);
        // watch imputWeek for changes
        let groupedImputations = groupBy(this.IMPUTATIONS, "task.id"); // group by task
        // window.console.log(groupedImputations);
        let keys = Object.keys(groupedImputations);
        if (keys)
          keys.map((key) => {
            groupedImputations[key].map((imputation, index) => {
              let charge =
                parseFloat(imputation.semaine ? imputation.semaine.lundi : 0) +
                parseFloat(imputation.semaine ? imputation.semaine.mardi : 0) +
                parseFloat(
                  imputation.semaine ? imputation.semaine.mercredi : 0
                ) +
                parseFloat(imputation.semaine ? imputation.semaine.jeudi : 0) +
                parseFloat(
                  imputation.semaine ? imputation.semaine.vendredi : 0
                ) +
                parseFloat(imputation.semaine ? imputation.semaine.samedi : 0) +
                parseFloat(
                  imputation.semaine ? imputation.semaine.dimanche : 0
                ); // calcul de la charge

              imputation.chargesEstime =
                index === 0
                  ? imputation.task.etp || 0
                  : groupedImputations[key][index - 1].chargesRestantes; // charge Estimee

              imputation.chargesRestantes =
                index === 0
                  ? imputation.chargesEstime - charge
                  : groupedImputations[key][index - 1].chargesRestantes -
                    charge; // charge Restante
            });
          });

        this.tableItems = this.IMPUTATIONS.filter((imputation) =>
          this.imputWeek
            ? moment(
                imputation.semaine ? imputation.semaine.semaine : new Date(),
                "DD/MM/YYYY"
              ).isoWeek() === moment(this.imputWeek).isoWeek()
            : moment(
                imputation.semaine ? imputation.semaine.semaine : new Date(),
                "DD/MM/YYYY"
              ).isoWeek() === moment(new Date()).isoWeek()
        );
      }
    },
    imputWeek: function () {
      this.tableItems = this.IMPUTATIONS.filter((imputation) =>
        this.imputWeek
          ? moment(
              imputation.semaine ? imputation.semaine.semaine : new Date(),
              "DD/MM/YYYY"
            ).isoWeek() === moment(this.imputWeek).isoWeek()
          : moment(
              imputation.semaine ? imputation.semaine.semaine : new Date(),
              "DD/MM/YYYY"
            ).isoWeek() === moment(new Date()).isoWeek()
      );
    },
  },
};
</script>

<style>
#imputation .mx-input {
  height: 44.22px !important;
}
::-webkit-input-placeholder {
  color: #cfcfcf;
}
.vs__search {
  color: #cfcfcf;
}
.bg-gray {
  background-color: gray;
}
</style>
